import React, { useEffect } from "react";
import HeroSection from "../components/HeroSection";
import WhoAreWeSection from "../components/main-page/WhoAreWeSection";
import WhatWeDoSection from "../components/main-page/WhatWeDoSection";
import OurWorkSection from "../components/main-page/OurWorkSection";
import MeetTheTeamSection from "../components/main-page/MeetTheTeamSection";
import OurClientsSection from "../components/main-page/OurClientsSection";
import ContactUsSection from "../components/main-page/ContactUsSection";
import FullDog from "../assets/images/full-dog.svg";

export default function MainPage() {
	useEffect(() => {
		document.title = "Borzoi Studio - Home";
	}, []);

	return (
		<>
			<HeroSection
				text1={"borzoi"}
				text2={"studio"}
				arrowDownHyperlink={"#about"}
				heroImage={FullDog}
			/>
			<WhoAreWeSection />
			<WhatWeDoSection />
			<OurWorkSection />
			{/* <MeetTheTeamSection /> */}
			<OurClientsSection />
			<ContactUsSection />
		</>
	);
}
